<template>
    <el-container>
        <!-- 头部 -->
        <el-header height="auto" class="header"><member-header /></el-header>
		
        <el-main>
            <transition name="slide"><router-view /></transition>
        </el-main>
    </el-container>
</template>
<script>
    import MemberHeader from "./components/MemberHeader"
    import NsHeader from "./components/NsHeader"
    //import NsAside from "./components/NsAside"
    //import CopyRight from "./components/CopyRight"

    export default {
        created() {},
        data: () => {
            return {}
        },
        mounted() {},
        computed: {},
        watch: {},
        components: {
            MemberHeader,
            //NsAside,
            //CopyRight
        }
    }
</script>
<style lang="scss" scoped>
    .header {
        padding: 0;
    }
    .content {
        //max-width: $width;
		max-width: 100%;
        margin: 0 auto;
        padding: 0;
    }

    .el-main {
		width: 100%;
		margin-top:2px;
    }
</style>
