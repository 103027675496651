export default {

	// api请求地址
	baseUrl: 'http://taizhou.unpopp.com',

	// 图片域名
	imgDomain: 'http://taizhou.unpopp.com',

	// 腾讯地图key
	 mpKey: '{{$mpKey}}',

	// 客服
	 webSocket: 'ws://taizhou.unpopp.com/wss',

	// api安全
	apiSecurity: "{{$apiSecurity}}",
	//apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: `{{$publicKey}}`,

}
