<template>
	<div class="header-top">
		<div class="top-left">
			<img src="@/assets/images/logo.png" alt />
		</div>
		<div class="top-right">
			<!-- <router-link v-if="!logined" to="/login" class="ns-text-color">您好，请登录</router-link>
			<div v-if="logined" class="member-info">
				<router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
				<span @click="logout">退出</span>
			</div> -->
			
			<el-input v-model="top_search" placeholder="产品搜索" suffix-icon="el-icon-search" style="width:220px;line-height:40px;margin-right:10px;"></el-input>

			
			<router-link to="/cms/notice"><img src="../../assets/images/top_message.png"/></router-link>
			<router-link to="/cart"><img src="../../assets/images/top_buy.png"/></router-link>
			<router-link to="/member/info">
				<img v-if="member.headimg" :src="$img(member.headimg)" @error="member.headimg = defaultHeadImage" width="40" height="40" style="border-radius: 20px;" />
				<img v-else :src="$img(defaultHeadImage)" width="40" height="40" />
			</router-link>

		</div>
	</div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { getToken } from "@/utils/auth"

    export default {
        props: {},
        data() {
            return {
				top_search:'',
				defaultHeadImage:'/upload/default/default_img/head.png'
			}
        },
        created() {
            this.$store.dispatch("site/qrCodes")
            this.$store.dispatch("member/member_detail")
            this.$store.dispatch("site/defaultFiles")
            this.$store.dispatch("site/addons")
        },
        mounted() {},
        watch: {},
        methods: {
            logout() {
                this.$store.dispatch("member/logout")
            }
        },
        components: {},
        computed: {
            ...mapGetters(["wapQrcode", "member", "addonIsExit", "city"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
            },
            logined: function() {
                return this.member !== undefined && this.member !== "" && this.member !== {}
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-top {
		position: fixed;
		top:0;
		z-index:999;
        width: 100%;
        height: 72px;
        font-size: 12px;
        background-color: #fff;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14);

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }

		.top-left {
			height: 36px;
			float: left;
			padding:18px 0 18px 12px;
		}
		.top-right {
			float: right;
			font-size: $ns-font-size-sm;
			color: $ns-text-color-black;
			padding-right:10px;
			padding-top:16px;
			a {
				float: left;
				line-height: 31px;
				padding: 0 10px;
				&:hover {
					color: $base-color;
				}
			}
			div {
				float: left;
				height: 100%;
				margin-left: 10px;
				cursor: pointer;
				line-height: 31px;
				padding: 0 5px;

				&.member-info {
					margin-left: 0;
					span:first-child {
						margin-right: 10px;
					}
				}
				span:hover {
					color: $base-color;
				}
				&.el-dropdown:hover {
					background-color: #fff;
				}
			}
        }
    }
    .mobile-qrcode {
        padding: 10px 0;
    }
	.router-link-active{
		color:$base-color;
	}
</style>
