<template>
    <el-container>
        <el-header><ns-header /></el-header>
        <el-main>
            <transition name="slide"><router-view /></transition>
        </el-main>
        <!-- 底部 -->
        <el-footer><ns-footer /></el-footer>
    </el-container>
</template>
<script>
    import NsHeader from "./components/NsHeader"
    import NsFooter from "./components/NsFooter"
    import NsAside from "./components/NsAside"
    import { adList } from "../api/website"

    export default {
        name: "Layout",
        components: {
            NsHeader,
            NsFooter,
            NsAside
        },
        created() {
            //this.getAdList()
        },
        data: () => {
            return {
                loadingAd: true,
                adList: [],
                is_show: false,
                indexTopAdNum: 0
            }
        },
        mounted() {},
        computed: {},
        watch: {},
        methods: {}
    }
</script>
<style lang="scss" scoped>
	.el-main{border-top:0px;}
    .banner {
        text-align: center;
        height: 70px;
        position: relative;
        i {
            font-size: 30px;
            position: absolute;
            z-index: 100;
            right: 280px;
            top: 10px;
            cursor: pointer;
            color: #d4d4d4;
        }
    }
	//.header{background: none!important;}
    .el-header {
        padding: 0;
        height: 135px !important;
		border-bottom:1px solid #333333;
    }
    .el-footer {
        padding: 0;
        height: auto !important;
        background-color: #fff;
        padding-top: 45px;
    }
</style>
